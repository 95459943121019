body {
  background-color: #fff5fd;
  font-family: "brown-pro", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.logo {
  height: auto;
  margin: 15px 0px;
  width: 21%;
  min-width: 300px;
}

.image {
  width: 60%;
  height: 100%;
}

.main h1 {
  font-size: 48pt;
  font-weight: normal;
  margin-top: -10px;
  line-height: 110%;
}

.main {
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: center;
  align-items: stretch;
}

.contact_wrapper {
  width: 40%;
  height: 30%;
}

.contact p {
  display: flex;
  font-weight: 400;
  align-items: flex-start;
  font-size: 24pt;
  margin: 20px 10px 0px 0px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.contact a {
  text-decoration: none;
  color: black;
}

.icon {
  font-size: 35pt;
  margin-right: 30px;
  margin-top: -4px;
}

.locicon {
  font-size: 30pt;
  margin-right: 30px;
  margin-top: 5px;
  margin-left: 4px;
}

iframe {
  margin-top: 25px;
  width: 400px;
}

.comingSoon {
  font-size: 3em;
  color: #fce4e8;
  font-weight: 400;
}

@media (max-width: 1023px) {
  .image,
  .logo {
    width: 70%;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main {
    flex-direction: column;
    gap: 0px;
    align-items: center;
  }

  .contact_wrapper {
    width: 70%;
    height: 70%;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }

  .contact h1 {
    font-size: 25pt;
    margin-bottom: 5px;
  }

  .contact p {
    font-size: 15pt;
  }

  .icon {
    font-size: 20pt;
    margin-top: -1px;
    margin-right: 20px;
  }

  .locicon {
    font-size: 17pt;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 20px;
  }

  iframe {
    margin-top: 25px;
    width: 100%;
  }
  .comingSoon {
    font-size: 1.5em;
    color: #fce4e8;
  }
}
